import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/lead/liste",
      component: () => import("@/view/layout/Layout"),
      children: [
        /**App**/
        {
          path: "/user",
          name: "user",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "user-liste",
              component: () => import("@/view/user/Liste.vue"),
              meta: {
                rule: 'isAdmin'
              }
            },
            {
              path: "nouveau",
              name: "user-nouveau",
              component: () => import("@/view/user/Detail.vue"),
              meta: {
                rule: 'isUser'
              }
            },
            {
              path: "detail/:id",
              name: "user-detail",
              component: () => import("@/view/user/Detail.vue"),
              meta: {
                rule: 'isUser'
              }
            }
          ]
        },
        {
          path: "/lead",
          name: "lead",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "lead-liste",
              component: () => import("@/view/app/lead/List.vue"),
              meta: {
                rule: 'isUser'
              }
            },
            {
              path: "nouveau",
              name: "lead-nouveau",
              component: () => import("@/view/app/lead/Detail.vue"),
              meta: {
                rule: 'isUser'
              }
            },
            {
              path: "detail/:id",
              name: "lead-detail",
              component: () => import("@/view/app/lead/Detail.vue"),
              meta: {
                rule: 'isUser'
              }
            }
          ]
        },
        {
          path: "/sms",
          name: "sms",
          component: () => import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children:[
            {
              path: "liste",
              name: "sms-liste",
              component: () => import("@/view/app/sms/Liste.vue"),
              meta: {
                rule: 'isUser'
              }
            }
          ]
        },
        /**App**/
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/app/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/app/Login"),
          meta: {
            rule: 'public'
          }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
      meta: {
        rule: 'public'
      }
    }
  ]
});
