import store from "@/core/services/store";
import router from "@/router";
import txt from "./text.json";
import CryptoJS from "crypto-js"
const BackService = {
  init() {
    this.urlAPI = "https://api.laf.jg.app";
    this.key = "5ftetxVm9GCaJ9j7wH2G96j676EnSu34XuR9mEESjUq5Y4M584S5mzV"
    this.user = null;
    this.lang = "fr";
    store.back = this;
    this.history = [];
    this.txt = txt;
    this.schema = {};
  },
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.urlAPI + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken()
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
        //router.push({path:'/login'})
        if(router.currentRoute.name === 'login' || router.currentRoute.name === null){
          return false;
        }else{
          router.push({path:'/login'})
        }
      }
      response.json().then( data =>{
        the.res = data;
        if(data.status === false){
          this.toast.toast(data.data, {
            title: 'Erreur',
            variant: 'danger',
            solid: true
          });
        }
        if(callBack != null) {
          callBack(the.res);
        }
      })
    }).catch( res => {
      console.log(res);
    });
  },
  getToken(){
    var t = window.localStorage.getItem("token");
    if(t === undefined){
      return false;
    }
    return t;
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(user){
    this.user = user;
  },
  getUser(){
    return this.user;
  },
  destroyToken(){
    window.localStorage.removeItem("token");
  },
  destroyUser(){
    this.user = null;
  },
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
  now(){
    var n = new Date().getTime();
    n = n/1000;
    return parseInt(n);
  },
  getText(key){
    var txt = this.txt[this.lang][key];
    if(txt === undefined){
      return '';
    }else{
      return txt;
    }
  },
  getSchema(name){
    var check = this.schema[name];
    if(check === undefined){
      return {}
    }
    return JSON.parse(JSON.stringify(check));
  },
  initSchema(){
    if(this.schema.UserRole === undefined){
      this.ajax('/magic/schema', null, (res) => {
        if(res.status){
          this.schema = res.data
        }
      })
    }
  },
  getParamTitre(array,ext_val){
    var check = array.find(x => x.id === ext_val);
    if(check === undefined){
      return "Aucun";
    }else{
      return check.titre
    }
  },
  encryptToken(){
    var t = CryptoJS.HmacSHA1(this.token,this.key)
    console.log(t);
  }
};

export default BackService;
