import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import acl from './acl';
import store from "@/core/services/store";
import Back from "@/core/services/back.service";
import MockService from "@/core/mock/mock.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import 'vue-search-select/dist/VueSearchSelect.css'

/*leaflet*/
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-popup', LPopup);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
// API service init
Back.init();
// Remove this to disable mock API
MockService.init();
async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Back.getToken()
    }
  };
  var url = Back.urlAPI + '/authorization/verify'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}

router.beforeEach((to, from, next) => {  
  if(to.meta.rule === 'public'){
    next();
    return true;
  }
  if(Back.getToken() !== false){
    backVerify().then(res => {
      if(res.status === true){
        Back.saveToken(res.data.token);
        Back.saveUser(res.data);
        next();
      }else{
        router.push({path:'/login'});
      }
    })
  }else{
    router.push({path:'/login'});
  }
  store.dispatch(RESET_LAYOUT_CONFIG);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  //acl,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  beforeMount() {
    Back.toast = this.$bvToast;
  }
}).$mount("#app");
