import BackService from "@/core/services/back.service";
//import store from "@/core/services/store/store";
// action types
export const APP_VERIFY_AUTH = "appVerifyAuth";
export const APP_LOGIN = "appLogin";
export const APP_LOGOUT = "appLogout";
export const APP_REGISTER = "appRegister";
export const APP_UPDATE_USER = "appUpdateUser";

// mutation types
export const APP_PURGE_AUTH = "appLogOut";
export const APP_SET_AUTH = "appSetUser";
export const APP_SET_ERROR = "appSetError";

const state = {
  errors: [],
  user: {
    email: null,
    expire_at: null,
    nom: null,
    prenom: null,
    role_id: null,
    token: null
  },
  isAuthenticated: !!BackService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [APP_LOGIN](context, credentials) {
    return new Promise(resolve => {
      BackService.ajax('/authorization/create',credentials, (res) => {
        if(res.status === true){
          context.commit(APP_SET_AUTH,res.data);
          resolve(res.data);
        }
      })
    })
  },
  [APP_LOGOUT](context) {
    context.commit(APP_PURGE_AUTH);
  },
  [APP_REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      BackService.ajax('/test/ping',null, (res) => {
        console.log(credentials);
        if(res.status === true){
          //context.commit(APP_SET_ERROR, res.data);
          resolve(res.data);
        }else{
          context.commit(APP_SET_ERROR, res.data);
          reject(res.data);
        }
      });
    });
  },
  [APP_VERIFY_AUTH](context) {
    if (BackService.getToken() !== false){
      backVerify().then(res => {
        if(res.status === true){
          context.commit(APP_SET_AUTH, res.data);
        }else{
          context.commit(APP_PURGE_AUTH);
        }
      }).catch(res => {
        context.commit(APP_SET_ERROR, res);
      })
    }else{
      context.commit(APP_PURGE_AUTH);
    }
  },
  [APP_UPDATE_USER](context, credentials) {
    return new Promise((resolve, reject) => {
      BackService.ajax('/test/ping',null, (res) => {
        console.log(credentials);
        if(res.status === true){
          context.commit(APP_SET_ERROR, res.data);
          resolve(res.data);
        }else{
          context.commit(APP_SET_ERROR, res.data);
          reject(res.data);
        }
      });
    })
  }
};

const mutations = {
  [APP_SET_ERROR](state, error) {
    state.errors = error;
  },
  [APP_SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = [];
    BackService.saveUser(user);
    BackService.saveToken(user.token);
  },
  [APP_PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    BackService.destroyToken();
  }
};

async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": BackService.getToken()
    }
  };
  var url = BackService.urlAPI + '/authorization/verify'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}

export default {
  state,
  actions,
  mutations,
  getters
};
